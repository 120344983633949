import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import './reset.css';
import './App.css';
import AdmissionStatus from './components/AdmissionStatus';
import MockAdmissionStatus from './components/MockAdmissionStatus';
import NotFoundPage from './components/NotFoundPage';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1d3868",
      white: "#ffffff",
      green: "#95CEBE",
      grey: "#E5E1DF",
      black: "#000",
      lightgrey: "lightgrey",
      slategrey: "slategrey",
      yellow: "#fdc54d"
    },
    secondary: {
      main: "#fff"
    },
    error: {
      main: "#FB7F59"
    }
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920
  //   }
  // }
});

function App() {
  // useEffect(() => {
  //   if (typeof window !== "undefined" && process.env.REACT_APP_ENVIRONMENT === "production") {
  //     ReactGA.set({dimension1: "test"});
  //     ReactGA.pageview(window.location.pathname);
  //   }
  // });
  
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
        <Route exact path="/application/:id"  component={AdmissionStatus}/>
        <Route exact path="/mock/:dealStage"  component={MockAdmissionStatus}/>
        <Route path="/"  component={NotFoundPage}/>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
