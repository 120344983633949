import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Card from './Card'
import { stepStates } from "./helpers"
import { ADMISSIONS_STEPS_ARR, getPaymentSteps } from "./admissionSteps"

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '60px 8.333vw',
        display: 'flex',
        justifyContent: 'center'
    },
    content: {
        
    }
}))

const Timeline = props => {
    const classes = useStyles()
    const {progressTo, funding, pipeline, advisorEmail} = props.data
    const paymentTerms = funding && funding.paymentTerms ? funding.paymentTerms : ''
    // let error = null
    // if(advisorEmail === undefined || advisorName === undefined || progressTo === undefined || paymentTerms === undefined || props.error){
    //     error = true
    // }

    const getStepState = (stepNum) => (progressTo > stepNum) ? stepStates.completed : ((progressTo === stepNum) ? stepStates.current : stepStates.pending)


    let ADMISSIONS_STEPS = [...ADMISSIONS_STEPS_ARR]
    if(paymentTerms !== ""){
        const paymentSteps = getPaymentSteps(paymentTerms)
        ADMISSIONS_STEPS[6] = paymentSteps['stepSeven']
    }

    // const isATAPipeline = pipeline && pipeline.toLowerCase().includes('ata');
    // let stepEightData = ADMISSIONS_STEPS[7];
    // if(isATAPipeline) {
    //     stepEightData.stepNumber = 7
    // }

    let stepTwoData = ADMISSIONS_STEPS[1](advisorEmail)
    return <div className={classes.wrapper}>
        <div className={classes.content}>
                <Card 
                    data={{...ADMISSIONS_STEPS[0], 
                        title: `Request Information${(props.data[ADMISSIONS_STEPS[0].key]['program']) ? ' - ' + props.data[ADMISSIONS_STEPS[0].key]["program"] : null}`
                    }}
                    stepState={getStepState(ADMISSIONS_STEPS[0].stepNumber)}
                    stepStatus={(ADMISSIONS_STEPS[0].key) ? props.data[ADMISSIONS_STEPS[0].key] : null}
                    />
                <Card 
                    data={stepTwoData}
                    stepState={getStepState(stepTwoData.stepNumber)}
                    stepStatus={(stepTwoData.key) ? props.data[stepTwoData.key] : null}
                />
                <Card 
                    data={ADMISSIONS_STEPS[2]}
                    stepState={getStepState(ADMISSIONS_STEPS[2].stepNumber)}
                    stepStatus={(ADMISSIONS_STEPS[2].key) ? props.data[ADMISSIONS_STEPS[2].key] : null}
                />
                <Card 
                    data={ADMISSIONS_STEPS[3]}
                    stepState={getStepState(ADMISSIONS_STEPS[3].stepNumber)}
                    stepStatus={(ADMISSIONS_STEPS[3].key) ? props.data[ADMISSIONS_STEPS[3].key] : null}
                    />
                <Card 
                    data={ADMISSIONS_STEPS[4]}
                    stepState={getStepState(ADMISSIONS_STEPS[4].stepNumber)}
                    stepStatus={(ADMISSIONS_STEPS[4].key) ? props.data[ADMISSIONS_STEPS[4].key] : null}
                    />
                <Card 
                    data={ADMISSIONS_STEPS[5]}
                    stepState={getStepState(ADMISSIONS_STEPS[5].stepNumber)}
                    stepStatus={(ADMISSIONS_STEPS[5].key) ? props.data[ADMISSIONS_STEPS[5].key] : null}
                    />
                {<Card
                    data={ADMISSIONS_STEPS[6]}
                    stepState={getStepState(ADMISSIONS_STEPS[6].stepNumber)}
                    stepStatus={(ADMISSIONS_STEPS[6].key) ? props.data[ADMISSIONS_STEPS[6].key] : null}
                    />}
                <Card
                    data={ADMISSIONS_STEPS[7]}
                    stepState={(progressTo >= 8) ? stepStates.completed : stepStates.pending }
                    stepStatus={(ADMISSIONS_STEPS[7].key) ? props.data[ADMISSIONS_STEPS[7].key] : null}
                    />
                {/* <Card
                    data={stepEightData}
                    stepState={(progressTo >= 8) ? stepStates.completed : stepStates.pending }
                    stepStatus={(ADMISSIONS_STEPS[7].key) ? props.data[ADMISSIONS_STEPS[7].key] : null}
                    /> */}
                {progressTo === 8 && 
                    <Card
                        data={ADMISSIONS_STEPS[8]}
                        clearWrapper
                    />
                }
        </div>
    </div>
}

export default Timeline