import React, { } from "react";
// import hero from '../../assets/portalBanner.jpeg'
import Welcome from './Welcome'
import { makeStyles } from "@material-ui/core/styles";

let hero = require('../../assets/KenzappHeader2.png')

const useStyles = makeStyles(theme => ({
    root: {
        "& .bannerContainer": {
            zIndex: '-1',
            position: 'absolute',
            // top:0,
            // left: 0,
            width: '100%',
            height: '311px',
            backgroundImage: `url(${hero})`,
            backgroundPosition: '50% 50%',
            backgroundSize: '115%',
            [theme.breakpoints.down('sm')]: {
                height: '264px',
                backgroundSize: '175%',
                backgroundPosition: '50% 10%',
            }
        },

        '& .bannerImg': {
            zIndex: '-3',
            position: 'relative',
            // width: '1425px',
            width:'100%',
            height: '100%',
            objectFit: 'cover',
            filter: 'opacity(0.3)',
            objectPosition: '0px 0px'
        },
        '& .headerContainer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '311px',
            [theme.breakpoints.down('sm')]: {
                height: '264px',
                alignItems: 'flex-start',
            }
        },
        '& .eleContainer': {
            width: '100%',
            maxWidth: '1920px',
            height: '70%',
            marginTop: '0px',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('xl')]: {
                paddingLeft: ''
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: 20
            }
        }
    }
}))

function Header(props) {
    const classes = useStyles();
    const data = props.data
    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className='headerContainer'>

                    <div className='bannerContainer'>
                        <div className='bannerImgContainer' >
                            {/* <img className='bannerImg' src={hero} alt='banner' /> */}
                        </div>
                    </div>

                    <div className='eleContainer'>
                        <Welcome data={{...data}} error={props.error}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Header