import Header from './Header/Header';
import Timeline from './Timeline/Timeline';
import NotFoundPage from './NotFoundPage';
import React, { useEffect, useState } from 'react';
import Loading from "./Loading"
import ConfettiContainer from "./ConfettiContainer"

export default function AdmissionStatus(props) {

   const [user, userState] = useState({
      userData: null,
      isLoading: true,
      error: false
   })

   useEffect(() => {
      async function getAdmissionStatus() {
         try{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admissions/status/${props.match.params.id}`);
            if (response.status === 200){
               const json = await response.json();
               console.log('With in response status 200') 
               userState({...user, isLoading: false, userData: {...json.message}});
            }else if(response.status === 400 || response.status === 502){
               console.log('With in response status 400 or 502')
               userState({...user, isLoading: false, error:'404'});
            }
            else{
               console.log('With in error 404')  
               userState({error:'404', isLoading: false, userData: null});
            }
         } catch {
            console.log('With in catch') 
            userState({...user, isLoading: false, error:'404'});
         }
      }
      if(user.isLoading && !user.userData && !user.error){
         getAdmissionStatus();
      }
   });

   if(user.isLoading){
      return <Loading />
   } else if(!user.isLoading && user.userData && user.userData.userName){
      let error = false
      if(user.userData.progressTo === undefined || user.userData.funding.paymentTerms === undefined || user.userData.currentStep === undefined){
         error = true
      }
      return (
         <div >
            <ConfettiContainer progressTo={user.userData.progressTo}/>
            <Header data={user.userData} error={error}/> 
            <Timeline data={user.userData} error={error}/>
         </div>
      )
   } else {
      return (
         <NotFoundPage />
      )
   }
}
