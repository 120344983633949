import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import {CircularProgress} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        [theme.breakpoints.down('md')]: {
            height: '75px',
        },
        [theme.breakpoints.up('md')]: {
            height: '125px',
        }
    }
}))

const Loading = props => {
    const classes = useStyles()
    return <div className={classes.wrapper}>
        <CircularProgress />
    </div>
}
export default Loading