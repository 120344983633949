import React from 'react'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core"
const useStyles = makeStyles(theme => ({
    text: {
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '18px',
        textAlign: 'center',
        color: theme.palette.primary.white,
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
        }
    },
    link: {
        color: theme.palette.primary.lightgrey,
        textDecoration: 'none',
        '& a:visited': {
            color: theme.palette.primary.lightgrey,
        },
        '&:hover': {
            color: theme.palette.primary.green,
        }
    },
    error: {
        color: theme.palette.primary.slategrey
    }
}))

const AdvisorCard = props => {
    const {advisorName, advisorEmail, error} = props
    const classes = useStyles()
    const theme = useTheme();
    const mobile = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
    return <div className={`${classes.advisorContainer} ${(error) ? classes.error : null}`}>
        <p className={classes.text}>Need help or have questions?{ mobile ? <br/> : ' '}You can reach out to your admissions advisor:</p>
        <p className={classes.text}>{advisorName} - <a href={`mailto:${advisorEmail}`} className={classes.link}>{advisorEmail}</a></p>
    </div>
}

export default AdvisorCard