import React from "react";
import { makeStyles } from '@material-ui/core/styles'
import logo from "../assets/LargeKenzieLogo.png"

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: '100vh',
        display: 'grid',
        gridTemplateRows: ' minmax(80px, 10vh) auto minmax(80px, 10vh)',
        gridTemplateColumns: '100vw',
        [theme.breakpoints.down('sm')]: {
            gridTemplateRows: ' minmax(25px, 8vh) auto minmax(25px, 8vh)'
        },
    },
    header: {
        gridRow: '0 / 1',
        width: '100%',
        height: '100%',
        background: '#1D3868',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
        headerLogo: {
            maxHeight: '80%'
        },
    content: {
        // gridRowStart: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
        messageWrapper: {
            width: '75%',
            height: '75%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '6px solid #95CEBE',
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '30%',
                border: '3px solid #95CEBE',
            }
        },
        messageSubWrapper: {
            width: '100%',
            height: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            fontFamily: 'Roboto Slab',
            fontStyle: 'normal',
            fontWeight: 500,
            textAlign: 'center',
            margin: 0,
            padding: 0,
            fontSize: '50px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '23px',
            }
        },
        subtitle: {
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'center',
            margin: 0,
            padding:0,
            fontSize: '24px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                padding: 8
            }
        },
        subtext: {
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'center',
            margin: 0,
            padding: 0,
            fontSize: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
            }
        },
        phone: {
            fontSize: '20px',
            marginTop: -10,
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                marginTop: 0
            }
        }
}))

const NotFoundPage = props => {
    const classes = useStyles()

    return <div className={classes.wrapper}>
        <div className={classes.header}>
            <img className={classes.headerLogo} src={logo} alt="Kenzie Academy Logo" />
        </div>
        <div className={classes.content}>
            <div className={classes.messageWrapper}>
                <div className={classes.messageSubWrapper}> {/*because flexbox*/}
                    <h1 className={classes.title}>We're sorry!</h1>
                    <h3 className={classes.title}>We can't find what you requested.</h3>
                    <p className={classes.subtitle}>Reach out to your advisor for more information.</p>
                    <p className={`${classes.subtext}`}>Still need help? Call Kenzie:</p>
                    <p className={`${classes.subtext} ${classes.phone}`}>317.644.4973</p>
                </div>
            </div>
        </div>
    </div>
}

export default NotFoundPage