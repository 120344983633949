import React, {useRef, useEffect, useState} from "react"
import Confetti from "react-dom-confetti"

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
    isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

const ConfettiContainer = (props) => {
    const [celebrate, setCelebrate] = useState(undefined)
    const config = {
        angle: "270",
        spread: "180",
        startVelocity: "20",
        elementCount: "200",
        dragFriction: "0.07",
        duration: "8000",
        stagger: 10,
        width: "10px",
        height: "10px",
        perspective: "500px",
        colors: ["#1D3868", "#95CEBE", "#FDC54C", "#0043BA", "#FB7F59"]
    };

    const isMount = useIsMount()
    useEffect(() => {
        if(isMount){
            setCelebrate(false)
        }
        if(!isMount && !celebrate && props.progressTo > 7) {
            setCelebrate(true)
        }
    }, [isMount, celebrate, props.progressTo])

    return (
        <div style={{width: '100vw', display: 'flex', justifyContent: 'center', maxWidth:'100vw'}}>
            <Confetti active={ celebrate } config={ config }/>
        </div>
    )
}

export default ConfettiContainer